import React from 'react'
import { RecWrapper, RecBox, RecDes, FloatImgRight, FloatImgLeft, Stepper } from '../styles/projectInfoStyle.js'
import Layout from '../components/layout'
import plane_photo from "../assets/images/senior_design/1finalrender4_2.jpg";
import systems_layout from "../assets/images/senior_design/2Systems Iso Layout 4 crop2.png";
import crosssection_photo from "../assets/images/senior_design/3cross_section_dimension.png";
import streamlines_photo from "../assets/images/senior_design/4streamlines_3D_view1.png";
import cabin_photo from "../assets/images/senior_design/Cabin Layout_final.png";

const AirVehicle = () => (
  <Layout>
  <RecWrapper>
    <h1>2nd Place AIAA High Capacity Short Range Transport Vehicle - Senior Design</h1>
    {
      <>

      <RecBox key={1} ><FloatImgRight>
                    <img
											src={plane_photo}
											alt="company"
                      width="1000px"
                      // style={{marginLeft:'40em'}}
										/>
        </FloatImgRight>
      <h3>Executive Summary</h3>
        <p>"Over the Pond, a collaborative enterprise of Virginia Tech and Hamburg students, is pleased to
offer Project Exo as the best solution to the AIAA’s request for proposal is for a high capacity, short range,
transport aircraft. The RFP calls for an aircraft that will reduce congestion at airports in a cost effective
manner. The aircraft is to have a reference mission of 700nm and a design range of 3,500nm with capacity
for 400 passengers in a dual class configuration. Commercial aircraft currently on the market are designed
for high capacity, long range missions, or low capacity, short range missions, neither of which solve the
congestion problem without an increased operating cost. Over the Pond provides the best solution to meet
the customer’s needs with an operating cost for the 700 nm reference mission of 12.3¢ per available seat
mile that is 20% less than that of the Boeing 777-300. The proposed design, Exo, shown in Figure 1, uses
composite materials to reduce weight of the wide-body fuselage. Exo leverages boundary layer ingestion
(BLI) propulsion system to reduce power required by 8%, and Rolls Royce’s UltraFan engine which is
20% more fuel efficient than other geared turbofan engines on the market. Implemented in the cockpit is a
single pilot capable, dual pilot equipped system, allowing for operation at reduced costs; autonomous taxi,
takeoff, and landing (ATTOL) is installed to support single pilot capability. Exo also uses aircraft health
monitoring system (AHMS), supported by AI, implemented in parallel with standard avionics equipment
to reduce aircraft downtime, decrease maintenance costs, and increase reliability. By using more electric
aircraft (MEA), Exo reduces fuel consumption and increases operational efficiency using components such
as EHA and no-bleed architecture. The cabin of Exo features a comfortable and innovative three aisle
layout, giving 83.5% of passengers a window or aisle seat, and allowing for a 27 minute boarding time,
almost 50% faster than the A321neo. This decrease board time, a cruise speed of Mach 0.82 and less than
25 minutes on the runway leads to 9 cycles per day, giving capability for more revenue flow to the buyer and
reducing airport congestion. Figure 1 displays Exo along with relevant aircraft specifications. Over the Pond
proposes to produce 10 aircraft per month which will take 42 months to break even with a 15% profit on
every aircraft sold thereafter. The majority of costs incurred are due to air-frame engineering, manufacturing,
engine acquisition, material and equipment purchase. Exo meets or exceeds all RFP requirements including
regulatory requirements per FAA 14 CFR Part 25, as seen in Table 1."</p>
<h4>Full proposal can be retrieved from AIAA’s website or via request from the authors.</h4>
      </RecBox>



      <RecBox key={2} >
      <FloatImgLeft>
        <img
											src={systems_layout}
											alt="company"
											width="800px"
										/>
        </FloatImgLeft>

      <h3>MEA Systems</h3>
        <p>"Exo uses innovative MEA system architecture by using a unified electric power type. This is
achieved with electrohydrostatic power-by-wire actuators, a no-bleed architecture using an all-electric environmental control system, engine starting system, and de-icing system.
The advantages over traditional systems architecture are numerous. A no-bleed architecture for the
de-icing, environmental control, and starting systems reduces the extracted power required of the engines by
30% compared to traditional systems, and removing the need for bleed-air extraction reduces the complexity of the engines and APU thus reducing maintenance cost and improving aircraft reliability. Reduced
power required for systems lowers fuel burn and operating cost. Electrohydrostatic actuators in a powerby-wire hydraulic system setup decentralizes the hydraulic system. Isolating the hydraulic lines and fluid
to each actuator reduces the likelihood and impact of a hydraulic system component failure. Maintenance
costs are reduced in two key ways; first, the location of a failure such as a leak is much simpler to determine.
Second, many backup systems required for a traditional centralized hydraulic system are rendered unnecessary. A unified electric power type reduces weight, improves power efficiency, improves system reliability,
and reduces maintenance costs. Exo has an advanced avionics suite supporting a single pilot capable cockpit with autonomous
taxi, takeoff, and landing capabilities. In addition, Exo is integrated with an aircraft health monitoring
system (AHMS) to help predict component failures using machine learning allowing for scheduled necessary
preventative maintenance. AHMS takes a prognostic approach to maintenance and system health, allowing
for the operator to be notified in real time when a component is at risk to fail (an incident), compared to
the conventional diagnostic approach, where warning is provided only upon the incident occurring. By
implementing AHMS, the need for hourly service inspections will be reduced, which directly lowers the
aircraft maintenance downtime, maintenance costs, and creates a more reliable, safer, and cost-effective
aircraft."</p>        
      </RecBox>






      <RecBox key={3} >
      <FloatImgRight>
        <img
											src={crosssection_photo}
											alt="company"
											width="700px"
										/>
    </FloatImgRight>
    <FloatImgRight>
            <img
                          src={cabin_photo}
                          alt="company"
                          width="1100px"
                        />
        </FloatImgRight>
      <h3>Cabin Design</h3>
        <p>"The decision on the economy abreast was made utilizing the decision matrix shown in Table 28.
With the exception of the first row in the buisiness class we adjusted the business abreast according to the
resulting cabin width. The boarding and evacuation process play an important role for the turn around time
as well as safety regulations and is rated in respect to aisles per passenger per row as well as passengers
distance to the door. The ratio of pay floor to total floor area, manufacturing cost and aerodynamic drag
are economically relevant. Wider abreast configurations result in a wider but shorter fuselage as the same
number of passengers fit in fewer rows and vice versa. The fuselage width and length affect the total floor,
cross section and wetted fuselage areas. The 2-3-3-2 configuration turned out to have the smallest floor and
wetted fuselage area, while the 2-2-2-2 shows the smallest cross section area. The attractiveness of seating
relates to the percentage of seats at windows or aisles, while the overhead compartment volume per seat are
driven by the cross section dimensions as well as passengers per row. Both of these effect the passenger
comfort... The amenities include galleys, lavatories and closets. Empirical values state, that for each 50
passengers one lavatory is required. With a total of 400 passengers, this relates to eight lavatories. Two of
these are located behind the business class separating it from the economy class. Another four can be found
at the end of the first economy section, the last two are in the rear of the aircraft. The galleys were sized
using the PreSTo tool. As a result, total galley space is 141.78 feet squared. This results in a large galley in
the aft section and two mid-sized galleys in the second section for the economy class and two mid-sized
galleys in the first section for the business class. Furthermore, two medium closets are located in the front
section of the business class to provide extra storage room for coats or other items. All cabin monuments are
placed in consideration of the center of gravity of the aircraft to ensure the in-flight stability. The equivalent
pressure altitude in cruise is 7,000 ft, reducing the time passengers need to adjust to changing flight phases
to account for short haul flight duration."
</p>

    
      </RecBox>



      <RecBox key={4} >
      <FloatImgRight>
        <img
											src={streamlines_photo}
											alt="company"
                      width="800px"
										/>
      </FloatImgRight>
      <h3>CFD on Boundary Layer Ingestion</h3>
        <p>"To validate the use of BLI on Exo, a Reynolds Average Navier Stokes based CFD model was
implemented to ensure feasibility of the propulsion concept. The center of the UltraFan inlet is 11f t outboard
and 5.4f t vertically with respect to the mid-planes of the fuselage. The inlet is located 182.5f t aft of the
nose. The following assumptions were made for the CFD model: engine inlet velocity boundary condition
will not affect flow in front of nozzle, pressure build up from engine nacelle will not largely affect results,
and only flow forward of the inlet is valid for analysis. The conditions for the simulations are listed in Table
26. Figure 37 shows streamlines of the flow at the empennage where BLI engines are located. Due to
the nature of an implicit unsteady computation and it’s variation over time, the result is shown at a specific
point in time. There is some distortion visible in the streamlines, hence the need for the distortion tolerant
fan. This distortion is generated due to the adverse pressure gradient occurring on the tapered portion of
the fuselage as seen in Figure 38a, which shows the velocity profile at the engine midpoint. This pressure
gradient can be eliminated by reducing the abruptness of the taper to the engine inlet. Looking closer at
the velocity profile just forward of the engine inlet, visible in Figure 38b, it can be seen that approximately
40% of the ingested air is from the boundary layer produced by the fuselage, with the remaining portion
being clean air. An improved empennage tapering would further increase the velocity intake outboard of the
empennage. Due to the similarity between the D8 and Exo, it can reasonably be expected to yield similar
performance benefits with BLI given Exo ingests the same amount of clean air and boundary layer as the
D8. Through use of CFD, it was verified that the engine ingests approximately 40% fuselage boundary layer
and 60% clean air. More extensive analysis and testing will be performed in the detailed design phase of the
project."</p>

<p></p>
      </RecBox>

      </>
    }
  </RecWrapper>
  </Layout>
)

export default AirVehicle
